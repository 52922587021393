import { Box, BoxProps, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
import { useGetCurrentUserQuery } from "@nerdjs/account-kit";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { RouterConfig } from "./hooks/config/routerConfig";
import AccountsRoute from "./routes/accounts";
import TransactionGroupsRoute from "./routes/transactionGroups";
import QuotasRoute from "./routes/quotas";
import ExpenseTypesRoute from "./routes/expenseTypes";
import NotFoundRoute from "./routes/notFoundRoute";
import ReportRoute from "./routes/report";
import ReportsRoute from "./routes/reports";
import TransactionsRoute from "./routes/transactions";
import VendorsRoute from "./routes/vendors";
import ScoresRoute from "./routes/scores";

const BoxWrapper = styled((props: { hideMarginTop?: boolean } & BoxProps) => (
  <Box {...props} />
))(({ theme, hideMarginTop }) => ({
  padding: "10px",
  display: "flex",
  marginTop:
    useMediaQuery(theme.breakpoints.down("sm")) || hideMarginTop
      ? "inherit"
      : "48px",
  background:
    theme.palette.mode !== "light"
      ? "rgba(0,0,0,0.9)"
      : "rgba(255,255,255,0.9)",
  backdropFilter: "blur(10px)",
  flexGrow: 1,
}));

/**
 *
 * @returns {React.ReactElement} the router
 */
export function AppRouter(): React.ReactElement {
  const { data: currentUser } = useGetCurrentUserQuery();
  return (
    <Routes>
      <Route
        path={RouterConfig.root}
        element={<Navigate to={RouterConfig.reports} />}
      ></Route>
      <Route
        path={RouterConfig.scores}
        element={
          <BoxWrapper hideMarginTop={currentUser ? false : true}>
            <ScoresRoute />
          </BoxWrapper>
        }
      ></Route>
      <Route
        path={RouterConfig.accounts}
        element={
          <BoxWrapper hideMarginTop={currentUser ? false : true}>
            <AccountsRoute />
          </BoxWrapper>
        }
      ></Route>
      <Route
        path={RouterConfig.vendors}
        element={
          <BoxWrapper hideMarginTop={currentUser ? false : true}>
            <VendorsRoute />
          </BoxWrapper>
        }
      ></Route>

      <Route
        path={RouterConfig.transactionGroups}
        element={
          <BoxWrapper hideMarginTop={currentUser ? false : true}>
            <TransactionGroupsRoute />
          </BoxWrapper>
        }
      ></Route>

      <Route
        path={RouterConfig.expenseTypes}
        element={
          <BoxWrapper hideMarginTop={currentUser ? false : true}>
            <ExpenseTypesRoute />
          </BoxWrapper>
        }
      ></Route>

      <Route
        path={RouterConfig.quotas}
        element={
          <BoxWrapper hideMarginTop={currentUser ? false : true}>
            <QuotasRoute />
          </BoxWrapper>
        }
      ></Route>

      <Route
        path={RouterConfig.transactions}
        element={
          <BoxWrapper hideMarginTop={currentUser ? false : true}>
            <TransactionsRoute />
          </BoxWrapper>
        }
      ></Route>

      <Route
        path={RouterConfig.reports}
        element={
          <BoxWrapper hideMarginTop={currentUser ? false : true}>
            <ReportsRoute />
          </BoxWrapper>
        }
      >
        <Route path={`:reportID/*`} element={<ReportRoute />}></Route>
      </Route>
      <Route
        path={"*"}
        element={
          <BoxWrapper hideMarginTop={currentUser ? false : true}>
            <NotFoundRoute />
          </BoxWrapper>
        }
      />
    </Routes>
  );
}
