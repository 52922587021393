import { nerdNetworkBaseQuery } from "@nerdjs/nerd-network";
import { createApi } from "@reduxjs/toolkit/query/react";

export const budgetApi = createApi({
  reducerPath: "budgetApi",
  baseQuery: (args, api) =>
    nerdNetworkBaseQuery(args, api, { service: "budget" }),
  endpoints: () => ({}),
  tagTypes: [
    "accounts",
    "transactionGroups",
    "quotas",
    "expenseTypes",
    "transactions",
    "vendors",
    "reports",
    "reportRows",
    "reportItems",
    "reportGroups",
    "scores",
  ],
});
