export const RouterConfig = {
  root: "/",
  accounts: "accounts",
  scores: "scores",
  vendors: "vendors",
  transactionGroups: "transactionGroups",
  quotas: "quotas",
  expenseTypes: "expenseTypes",
  transactions: "transactions",
  reports: "reports",
};
